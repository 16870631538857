























































































































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import { CustomerDto, CustomerType } from "@/api/appService";
import PagedTableView from "@/components/PagedTableView.vue";
import AbSelect from "@/components/AbSelect.vue";

@Component({
  components: {
    PagedTableView,
    AbSelect,
  },
})
export default class Enterprise extends Vue {
  editId = 0;
  queryForm = {
    customerCode: "",
    realName: "",
    mobilePhone: "",
    email: "",
    customerType: CustomerType.Ordinary,
  };

  fetchData(params: never) {
    return api.customer.getAll(params);
  }

  //禁用
  handleDisable(index: number, row: CustomerDto) {
    this.$confirm(
      (this as any).$l.getLanguageText("basic.disableTip").toString(),
      (this as any).$l.getLanguageText("basic.tip").toString()
    ).then(() => {
      api.user
        .deActivate({
          body: {
            id: row.fkUser?.id,
          },
        })
        .then(() => {
          this.$message.success(
            (this as any).$l.getLanguageText("basic.disableSuccess").toString()
          );
        });
    });
  }

  //启用
  handleEnable(index: number, row: CustomerDto) {
    api.user
      .activate({
        body: {
          id: row.fkUser?.id,
        },
      })
      .then(() => {
        this.$message.success(
          (this as any).$l.getLanguageText("basic.enableSuccess").toString()
        );
      });
  }
}
